/* eslint-disable @next/next/no-sync-scripts */
import Head from 'next/head';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from 'styled-components';

import { UnitedGlobalStyle, vndaTheme } from '@olist/united';

import i18n from '@i18n/setup';

import { GlobalStyle } from '@styles/globals';
import theme from '@styles/theme';

import type { AppProps } from 'next/app';

const customTheme = {
  ...vndaTheme,
  breakpoints: theme.breakpoints,
};

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer', "${process.env.NEXT_PUBLIC_GTM_ID}");
            `,
          }}
        />
        <script src={`https://www.googletagmanager.com/gtag/js?id=${process.env.TAG_ID}`} />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.TAG_ID}');
            `,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
          (function(c, a) {
            if (!a.__SV) {
              var b = window;
              try {
                var d, m, j, k = b.location,
                  f = k.hash;
                d = function(a, b) {
                  return (m = a.match(RegExp(b + "=([^&]*)"))) ? m[1] : null
                };
                f && d(f, "state") && (j = JSON.parse(decodeURIComponent(d(f, "state"))), "mpeditor" === j.action && (b.sessionStorage.setItem("_mpcehash", f), history.replaceState(j.desiredHash || "", c.title, k.pathname + k.search)))
              } catch (n) {}
              var l, h;
              window.mixpanel = a;
              a._i = [];
              a.init = function(b, d, g) {
                function c(b, i) {
                  var a = i.split(".");
                  2 == a.length && (b = b[a[0]], i = a[1]);
                  b[i] = function() {
                    b.push([i].concat(Array.prototype.slice.call(arguments,
                      0)))
                  }
                }
                var e = a;
                "undefined" !== typeof g ? e = a[g] = [] : g = "mixpanel";
                e.people = e.people || [];
                e.toString = function(b) {
                  var a = "mixpanel";
                  "mixpanel" !== g && (a += "." + g);
                  b || (a += " (stub)");
                  return a
                };
                e.people.toString = function() {
                  return e.toString(1) + ".people (stub)"
                };
                l = "disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
                for (h = 0; h < l.length; h++) c(e, l[h]);
                var f = "set set_once union unset remove delete".split(" ");
                e.get_group = function() {
                  function a(c) {
                    b[c] = function() {
                      call2_args = arguments;
                      call2 = [c].concat(Array.prototype.slice.call(call2_args, 0));
                      e.push([d, call2])
                    }
                  }
                  for (var b = {}, d = ["get_group"].concat(Array.prototype.slice.call(arguments, 0)), c = 0; c < f.length; c++) a(f[c]);
                  return b
                };
                a._i.push([b, d, g])
              };
              a.__SV = 1.2;
              b = c.createElement("script");
              b.type = "text/javascript";
              b.async = !0;
              b.src = "undefined" !== typeof MIXPANEL_CUSTOM_LIB_URL ? MIXPANEL_CUSTOM_LIB_URL : "file:" === c.location.protocol && "//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match() ? "https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js" : "//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";
              d = c.getElementsByTagName("script")[0];
              d.parentNode.insertBefore(b, d)
            }
          })(document, window.mixpanel || []);
        `,
          }}
        />

        <script
          id="mcjs"
          dangerouslySetInnerHTML={{
            __html: `
          !function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/8af708ccc5da83ee7d4d8c1c3/5c43d7ba6bd9e931dc5838be1.js");
        `,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
          _linkedin_partner_id = "3951265";
          window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
          window._linkedin_data_partner_ids.push(_linkedin_partner_id);
          (function(l) {
          if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q=[]}
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})(window.lintrk);
        `,
          }}
        />
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: 'none' }}
            alt=""
            src="https://px.ads.linkedin.com/collect/?pid=3951265&fmt=gif"
          />
        </noscript>
        <script
          dangerouslySetInnerHTML={{
            __html: `
          (function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"22023049"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");
          function uet_report_conversion() {window.uetq = window.uetq || [];window.uetq.push('event', 'submit_lead_form', {});}
        `,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
          (function(e, t, o, n, p, r, i) {
            e.visitorGlobalObjectAlias = n;
            e[e.visitorGlobalObjectAlias] = e[e.visitorGlobalObjectAlias] || function() {
              (e[e.visitorGlobalObjectAlias].q = e[e.visitorGlobalObjectAlias].q || []).push(arguments)
            };
            e[e.visitorGlobalObjectAlias].l = (new Date).getTime();
            r = t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];
            i.parentNode.insertBefore(r, i)
          })(window, document, "https://diffuser-cdn.app-us1.com/diffuser/diffuser.js", "vgo");
          vgo('setAccount', '800696281');
          vgo('setTrackByDefault', true);
          vgo('process');
        `,
          }}
        />
        <script type="module" src="/main.js" />

        <script src="https://vnda.activehosted.com/f/embed.php?id=7" type="text/javascript" />

        <meta name="adopt-website-id" content="4a63238a-2555-4fc7-9510-adb9e633ada4" />
        <script
          src="https://tag.goadopt.io/injector.js?website_code=4a63238a-2555-4fc7-9510-adb9e633ada4"
          className="adopt-injector"
        />
      </Head>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={customTheme}>
          <UnitedGlobalStyle />
          <GlobalStyle />
          <Component {...pageProps} />
        </ThemeProvider>
      </I18nextProvider>
    </>
  );
}

export default MyApp;
