/* eslint-disable prefer-destructuring */
export enum BreakpointEnum {
  EXTRA_SMALL = 'extraSmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  EXTRA_LARGE = 'extraLarge',
  DOUBLE_EXTRA_LARGE = 'doubleExtraLarge',
}

type BreakpointsProp = Array<string> & {
  extraSmall?: string;
  small?: string;
  medium?: string;
  large?: string;
  extraLarge?: string;
  doubleExtraLarge?: string;
};

export const breakpoints: BreakpointsProp = [
  '450px',
  '768px',
  '1024px',
  '1280px',
  '1920px',
  '2560px',
];
breakpoints.extraSmall = breakpoints[0];
breakpoints.small = breakpoints[1];
breakpoints.medium = breakpoints[2];
breakpoints.large = breakpoints[3];
breakpoints.extraLarge = breakpoints[4];
breakpoints.doubleExtraLarge = breakpoints[5];
