import { themeGet } from '@styled-system/theme-get';
import { createGlobalStyle, css } from 'styled-components';
import reset from 'styled-reset';
import 'swiper/swiper-bundle.min.css';

export const GlobalStyle = createGlobalStyle`
  ${reset}

  html, body, #root, input, select {
    font-family: ${themeGet('font')};
    overflow-x: hidden;
  }

  html {
    scroll-behavior: smooth;
  }

  #__next {
    position: relative;
  }

  body {
    font-family: ${themeGet('font')};
    line-height: 1.5;
    position: relative;
    padding-top: 63px;
    overflow-x: visible !important;
  }

  b { font-weight: ${themeGet('olist.fontWeight.bold')}; }

  button { font-family: ${themeGet('font')}; }

  h1, h2, h3, h4, h5, h6, strong {
    > span {
      color: #0c29d0;
      &:last-child {
        color: transparent;
        display: inline-block;
        border-radius: 100%;
        background: #0c29d0;
        vertical-align: bottom;
        line-height: 0;
        margin-left: 3px;
      }
    }
    > strong {
      font-weight: 600;
    }
  }

  h1, h2 {
    > span {
      &:last-child {
        width: 6px;
        height: 6px;
      }
    }
  }

  h3, h4, h5, h6 {
    > span {
      &:last-child {
        width: 4px;
        height: 4px;
      }
    }
  }

  p {
    strong {
      font-weight: 600;
    }
  }

  h1, h1 > p {
    font-weight: 700;
    font-size: 40px;
    line-height: 120%;
    color: #363232;
    > span:last-child {
      margin-bottom: 2%;
    }
    > p{
      display: inline;
      > strong {
        color: #0c29d0;
      }
    }
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.small}) {
            font-weight: 700;
            font-size: 35.6989px;
            line-height: 120%;
          }
          @media (max-width: ${theme.breakpoints.extraSmall}) {
            font-weight: 700;
            font-size: 28px;
            line-height: 120%;
          }
        `
      );
    }}
  }

  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    color: #363232;
    > span:last-child {
      margin-bottom: 8px;
    }
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.small}) {
            font-weight: 700;
            font-size: 32px;
            line-height: 120%;
          }
          @media (max-width: ${theme.breakpoints.extraSmall}) {
            font-weight: 700;
            font-size: 24px;
            line-height: 120%;
          }
        `
      );
    }}
  }

  h3 {
    font-weight: 700;
    font-size: 28px;
    line-height: 120%;
    color: #363232;
  }

  h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    color: #0c29d0;
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.extraSmall}) {
            font-weight: 700;
            font-size: 20px;
            line-height: 120%;
          }
        `
      );
    }}
  }

  h5 {
    font-weight: 700;
    font-size: 22px;
    line-height: 120%;

    > span:last-child {
      margin-bottom: 2%;
    }

    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.extraSmall}) {
            font-weight: 700;
            font-size: 20px;
            line-height: 120%;
          }
        `
      );
    }}
  }

  h6 {
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;


    > span:last-child {
      margin-bottom: .5%;
    }
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.large}) {
            font-weight: 400;
            font-size: 18px;
            line-height: 150%;
          }
        `
      );
    }}
  }

  .subtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.large}) {
            font-size: 16px;
          }
        `
      );
    }}
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }

  .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0;
    .swiper-pagination-bullet {
      background: #CFCFCF;
      height: 10px;
      margin: 0 5px;
      opacity: 1;
      width: 10px;
      &.swiper-pagination-bullet-active {
        height: 12px;
        width: 12px;
        background: #0c29d0;
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    height: 20px;
    width: 20px;
    top: 50%;
    transform: translateY(50%);
    &:after {
      color: black;
      font-size: 16px;
      transition: 0.3s;
    }
    &:hover {
      &:after {
        color: #0c29d0;
      }
    }
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }
  .section{
    padding: calc(30px * 1.5) 0;
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.large}) {
            padding: calc(30px / 2) 0;
          }
        `
      );
    }}
  }
`;
